<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="distributor.avatar ? '' : 'primary'"
            :class="distributor.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-0"
          >
            <v-img
              v-if="distributor.profile?.photo"
              :src="distributor.profile.photo.thumbnail"
              style="border: solid 1px #eee !important;"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold text-5xl"
            >{{ avatarText(distributor.nicename) }}</span>
          </v-avatar>

          <span
            class="text-xs text--secondary mb-0"
            @click="$emit('openEditPhotoAside')"
          >
            <v-icon
              size="18"
              class="mr-0 mb-2"
            >
              {{ icons.mdiPencilOutline }}
            </v-icon> Edit Photo
          </span>
          <span class="mb-2">{{ distributor.nicename }}</span>

          <v-chip
            v-if="distributor.active"
            color="success"
          >
            <v-icon
              size="20"
              class="mr-1"
            >
              {{ icons.mdiCheck }}
            </v-icon> Active
          </v-chip>
          <v-chip
            v-else
            color="error"
          >
            Inactive
          </v-chip>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Profile
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Business Name:</span>
              <span class="text--secondary">{{ distributor.business_name }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">NiceName:</span>
              <span class="text--secondary">{{ distributor.nicename }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Email:</span>
              <span class="text--secondary">{{ distributor.profile?.email ?? '--' }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Phone:</span>
              <span
                v-if="distributor.profile?.country_code"
                class="text--secondary"
              >{{ distributor.profile?.country_code }}
                {{ distributor.profile?.phone }}</span>
              <span
                v-else
                class="text--secondary"
              > --</span>
            </v-list-item>

            <!-- <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Enable Medical:</span>
              <span
                class="text--secondary">
                {{ distributor.rules.enable_medical ? 'Enable' : 'Disable' }}
                </span>
            </v-list-item> -->

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Country:</span>
              <span
                class="text--secondary"
              >{{ t(distributor.profile?.country.name) ?? '--' }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Industry:</span>
              <span
                class="text--secondary"
              >{{ t(distributor.profile?.industry.name) ?? '--' }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Financial Start Date:</span>
              <span
                class="text--secondary"
              >{{ moment(distributor.profile?.financial_start_date).format('Do MMM') ?? '--' }}</span>
            </v-list-item>
          </v-list>

          <v-btn
            color="primary"
            @click="() => { aside = 'distributor'; open = true }"
          >
            Edit Distributor
          </v-btn>

          <v-btn @click="() => { aside = 'profile'; open = true }">
            Edit profile
          </v-btn>

          <distributor-aside
            v-if="aside === 'distributor'"
            v-model="open"
            :resource="distributor"
            @changed="updated"
          ></distributor-aside>

          <distributor-profile-aside
            v-if="aside === 'profile'"
            v-model="open"
            :resource="distributor"
            @changed="updated"
          >
          </distributor-profile-aside>
        </v-card-text>

        <!-- <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">HealthCoin</h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Remaining:</span>
              <span class="text--secondary">{{ healthCoins }}</span>
            </v-list-item>
          </v-list>
        </v-card-text> -->

        <v-card-actions class="justify-center">
          <!-- <v-btn
            color="primary"
            class="me-3"
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            Edit
          </v-btn> -->
          <!-- <v-btn
            color="error"
            outlined
          >
            Suspended
          </v-btn> -->
        </v-card-actions>
      </v-card>

      <!-- edit profile dialog data -->
      <!-- <user-bio-edit :is-bio-dialog-open.sync="isBioDialogOpen" :user-data="user"></user-bio-edit> -->
    </v-col>
  </v-row>
</template>

<script>
import DistributorAside from '@/modules/distributor/components/distributor/DistributorAside.vue'
import DistributorProfileAside from '@/modules/distributor/components/distributor/DistributorProfileAside.vue'
import { t } from '@/plugins/i18n'
import { avatarText, kFormatter } from '@core/utils/filter'
import {
mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle, mdiPencilOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import moment from 'moment'

export default {
  components: {
    DistributorAside,
    DistributorProfileAside,
  },
  props: {
    distributor: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isBioDialogOpen = ref(false)

    const fullName = computed(() => 'fullname')

    // const healthCoins = computed(() => {
    //   return props.distributor.reward_points.reduce((acc, next) => {
    //     return acc + next.points
    //   }, 0)
    // })
    const aside = ref('')
    const open = ref(false)

    return {
      avatarText,
      kFormatter,

      isBioDialogOpen,
      aside,
      open,
      updated() {
        aside.value = ''
        open.value = false
        emit('updated')
      },

      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiCheck,
      },

      fullName,

      // healthCoins,
      t,
      moment,
      icons: {
        mdiPencilOutline,
      },
    }
  },
}
</script>
