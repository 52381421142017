var render = function () {
  var _vm$distributor$profi, _vm$distributor$profi2, _vm$distributor$profi3, _vm$distributor$profi4, _vm$distributor$profi5, _vm$distributor$profi6, _vm$t, _vm$distributor$profi7, _vm$t2, _vm$distributor$profi8, _vm$moment$format, _vm$distributor$profi9;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "user-bio-panel"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "pt-10"
  }, [_c('v-card-title', {
    staticClass: "justify-center flex-column"
  }, [_c('v-avatar', {
    staticClass: "mb-0",
    class: _vm.distributor.avatar ? '' : 'v-avatar-light-bg primary--text',
    attrs: {
      "color": _vm.distributor.avatar ? '' : 'primary',
      "size": "120",
      "rounded": ""
    }
  }, [(_vm$distributor$profi = _vm.distributor.profile) !== null && _vm$distributor$profi !== void 0 && _vm$distributor$profi.photo ? _c('v-img', {
    staticStyle: {
      "border": "solid 1px #eee !important"
    },
    attrs: {
      "src": _vm.distributor.profile.photo.thumbnail
    }
  }) : _c('span', {
    staticClass: "font-weight-semibold text-5xl"
  }, [_vm._v(_vm._s(_vm.avatarText(_vm.distributor.nicename)))])], 1), _c('span', {
    staticClass: "text-xs text--secondary mb-0",
    on: {
      "click": function click($event) {
        return _vm.$emit('openEditPhotoAside');
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-0 mb-2",
    attrs: {
      "size": "18"
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiPencilOutline) + " ")]), _vm._v(" Edit Photo ")], 1), _c('span', {
    staticClass: "mb-2"
  }, [_vm._v(_vm._s(_vm.distributor.nicename))]), _vm.distributor.active ? _c('v-chip', {
    attrs: {
      "color": "success"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "size": "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiCheck) + " ")]), _vm._v(" Active ")], 1) : _c('v-chip', {
    attrs: {
      "color": "error"
    }
  }, [_vm._v(" Inactive ")])], 1), _c('v-card-text', [_c('h2', {
    staticClass: "text-xl font-weight-semibold mb-2"
  }, [_vm._v(" Profile ")]), _c('v-divider'), _c('v-list', [_c('v-list-item', {
    staticClass: "px-0 mb-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-medium me-2"
  }, [_vm._v("Business Name:")]), _c('span', {
    staticClass: "text--secondary"
  }, [_vm._v(_vm._s(_vm.distributor.business_name))])]), _c('v-list-item', {
    staticClass: "px-0 mb-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-medium me-2"
  }, [_vm._v("NiceName:")]), _c('span', {
    staticClass: "text--secondary"
  }, [_vm._v(_vm._s(_vm.distributor.nicename))])]), _c('v-list-item', {
    staticClass: "px-0 mb-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-medium text-no-wrap me-2"
  }, [_vm._v("Email:")]), _c('span', {
    staticClass: "text--secondary"
  }, [_vm._v(_vm._s((_vm$distributor$profi2 = (_vm$distributor$profi3 = _vm.distributor.profile) === null || _vm$distributor$profi3 === void 0 ? void 0 : _vm$distributor$profi3.email) !== null && _vm$distributor$profi2 !== void 0 ? _vm$distributor$profi2 : '--'))])]), _c('v-list-item', {
    staticClass: "px-0 mb-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-medium me-2"
  }, [_vm._v("Phone:")]), (_vm$distributor$profi4 = _vm.distributor.profile) !== null && _vm$distributor$profi4 !== void 0 && _vm$distributor$profi4.country_code ? _c('span', {
    staticClass: "text--secondary"
  }, [_vm._v(_vm._s((_vm$distributor$profi5 = _vm.distributor.profile) === null || _vm$distributor$profi5 === void 0 ? void 0 : _vm$distributor$profi5.country_code) + " " + _vm._s((_vm$distributor$profi6 = _vm.distributor.profile) === null || _vm$distributor$profi6 === void 0 ? void 0 : _vm$distributor$profi6.phone))]) : _c('span', {
    staticClass: "text--secondary"
  }, [_vm._v(" --")])]), _c('v-list-item', {
    staticClass: "px-0 mb-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-medium text-no-wrap me-2"
  }, [_vm._v("Country:")]), _c('span', {
    staticClass: "text--secondary"
  }, [_vm._v(_vm._s((_vm$t = _vm.t((_vm$distributor$profi7 = _vm.distributor.profile) === null || _vm$distributor$profi7 === void 0 ? void 0 : _vm$distributor$profi7.country.name)) !== null && _vm$t !== void 0 ? _vm$t : '--'))])]), _c('v-list-item', {
    staticClass: "px-0 mb-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-medium text-no-wrap me-2"
  }, [_vm._v("Industry:")]), _c('span', {
    staticClass: "text--secondary"
  }, [_vm._v(_vm._s((_vm$t2 = _vm.t((_vm$distributor$profi8 = _vm.distributor.profile) === null || _vm$distributor$profi8 === void 0 ? void 0 : _vm$distributor$profi8.industry.name)) !== null && _vm$t2 !== void 0 ? _vm$t2 : '--'))])]), _c('v-list-item', {
    staticClass: "px-0 mb-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-medium text-no-wrap me-2"
  }, [_vm._v("Financial Start Date:")]), _c('span', {
    staticClass: "text--secondary"
  }, [_vm._v(_vm._s((_vm$moment$format = _vm.moment((_vm$distributor$profi9 = _vm.distributor.profile) === null || _vm$distributor$profi9 === void 0 ? void 0 : _vm$distributor$profi9.financial_start_date).format('Do MMM')) !== null && _vm$moment$format !== void 0 ? _vm$moment$format : '--'))])])], 1), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click() {
        _vm.aside = 'distributor';
        _vm.open = true;
      }
    }
  }, [_vm._v(" Edit Distributor ")]), _c('v-btn', {
    on: {
      "click": function click() {
        _vm.aside = 'profile';
        _vm.open = true;
      }
    }
  }, [_vm._v(" Edit profile ")]), _vm.aside === 'distributor' ? _c('distributor-aside', {
    attrs: {
      "resource": _vm.distributor
    },
    on: {
      "changed": _vm.updated
    },
    model: {
      value: _vm.open,
      callback: function callback($$v) {
        _vm.open = $$v;
      },
      expression: "open"
    }
  }) : _vm._e(), _vm.aside === 'profile' ? _c('distributor-profile-aside', {
    attrs: {
      "resource": _vm.distributor
    },
    on: {
      "changed": _vm.updated
    },
    model: {
      value: _vm.open,
      callback: function callback($$v) {
        _vm.open = $$v;
      },
      expression: "open"
    }
  }) : _vm._e()], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }